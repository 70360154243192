import React, {useState, useEffect, useContext} from "react";
import { authentication, pages } from "@microsoft/teams-js";
import { makeResetStyles, makeStyles, Spinner } from "@fluentui/react-components";
import Ows from "../utils/OwsHandler";
import Message from "../blocks/Message";
import SignedPart from "../features/configuration/SignedPart";
import SecondFactorPart from "../features/configuration/SecondFactorPart";
import { customTokens } from "../utils/Theming";
import LoginPart from "../features/configuration/LoginPart";
import { ConfigurationMode } from "../utils/Constants";
import { TeamsFxContext } from "../Context";


const useBaseClassName = makeResetStyles({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
});
const useStyles = makeStyles({
  messageContainer: {
    position: 'fixed',
    top: 0,
    left: customTokens.spacingHorizontalL,
    right: customTokens.spacingHorizontalL,
    zIndex: 1000
  }
});

export default function ConfigPage(){
  const baseClassName = useBaseClassName();
  const classes = useStyles();
  const [mode, setMode] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [user, setUser] = useState(null);

  const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;

  const cleanForm = () => {
    setErrorMessage('');
  };

  const silentLogin = async () => {
    try {
      const user = await Ows.getCurrentUser();
      const blocked = await Ows.checkTotpBlock();
      if (blocked) {
        setMode(ConfigurationMode.TOTP);
        return;
      }
      setMode(ConfigurationMode.SIGNED);
      setUser(user);
    } catch (e) {
      //setErrorMessage(e.message);
      setMode(ConfigurationMode.LOGIN);
      console.error(e);
    }
  };

  const login = async (username, password) => {
    cleanForm();
    try {
      const isTOTPActive = await Ows.validateViaUsernamePassword(username, password);
      if (isTOTPActive) {
        setMode(ConfigurationMode.TOTP);
      } else {
       await silentLogin();
      }
    } catch (e) {
      if (e.message === 'Login failed.') {
        setErrorMessage('Invalid credentials');
      }
      //setErrorMessage(e.message);
    }
  };

  const azureLogin = async () => {
    cleanForm();
    const token = await authentication.getAuthToken({ silent: true });
    try {
      await Ows.validateViaMsUser(token);
      await silentLogin();
    } catch (e) {
      setErrorMessage(e.message + '<br/><br/></i>"Login via Azure" can be enabled upon request by contacting OTYS Support (https://www.otys.com/en/support) or through your designated consultant. This feature is not enabled by default. For further assistance, please do not hesitate to reach out to us.<br/><br/>Please use your standard login via Otys username and password instead.</i>');
    }
  };

  const validateTOTP = async (code) => {
    cleanForm();
    try {
      await Ows.validateViaTotp(code);
      await silentLogin();
    } catch (e) {
      setErrorMessage('Failed to validate TOTP code, please try again');
    }
  };

  const logout = async () => {
    cleanForm();
    await Ows.logout();
    setMode(ConfigurationMode.LOGIN);
  };

  const authorizeConsent = async () => {
    console.log('Authorization triggered 01 -1');
    console.log('TeamsFxContext 01 -1', TeamsFxContext);
    console.log('teamsUserCredential 01 -1', teamsUserCredential);

    try {
        await teamsUserCredential.getToken(["User.Read"]);
        console.log("Consent: User already authenticated");
    } catch (error) {
        console.log("Consent: Consent required, triggering login...", error);
        try {
            const result = await teamsUserCredential.login(["User.Read"]);   
            console.log('Consent: Login successful:', result);
        } catch (error) {
            console.log('Consent: Login failed:', error);
        }
    }
  }

  useEffect(() => {
    (async () => {
      //await authorizeConsent();
      await silentLogin();
    })();    
  }, []);
  const url = `${window.location.origin}${window.location.pathname}#/meeting-detail`;
  pages.config.registerOnSaveHandler(async (saveEvent) => {
    await pages.config.setConfig({
      contentUrl: url,
      entityId: `MeetingDetailTab`,
    });
    await Ows.configureSSO();
    saveEvent.notifySuccess();
  });

  pages.config.setValidityState(mode === ConfigurationMode.SIGNED);
  let content = '';
  switch(mode) {
    case ConfigurationMode.LOGIN: 
      content = (<LoginPart onLogin={login} onLoginWithAzure={azureLogin} setErrorMessage={setErrorMessage}/>);
      break;
    case ConfigurationMode.TOTP:
      content = (<SecondFactorPart onValidate={validateTOTP} />);
      break;
    case ConfigurationMode.SIGNED:
      content = (<SignedPart name={user.username} onLogout={logout} />);
      break;
    default:
      content = (<Spinner />);
  }

  return (
    <div className={baseClassName}>
      { errorMessage && 
        <div className={classes.messageContainer}>
          <Message intent="error" message={errorMessage} onClose={() => setErrorMessage('')} />
        </div>
      }
      {content}
    </div>
  );
}