import React from "react";
import { useState, useContext } from "react";
import { Button, Text, Field, Label, Input, Divider, makeResetStyles, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { customTokens } from "../../utils/Theming";
import { TeamsFxContext } from "../../Context";
import { call } from "@microsoft/teams-js";
import Api from "../../utils/ApiHandler";

const useBaseClassName = makeResetStyles({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: `calc(500px + ${customTokens.spacingHorizontalL})`,
    ...shorthands.margin('0', 'auto'),
    ...shorthands.padding('0', customTokens.spacingHorizontalL),
    '& > .block': {
        '&:not(:last-child)': {
            marginBottom: customTokens.spacingVerticalL,
        },
    },
});
const useStyles = makeStyles({
    actionColor: {
        color: customTokens.colorButtonBackground1
    },
    actionBackground: {
        backgroundColor: customTokens.colorButtonBackground1
    },
    infoBlock: {
        '& > *': {
            verticalAlign: 'middle',
        },
        '& > svg': {
            marginRight: customTokens.spacingHorizontalS
        }
    },
    divider: {
        "::before": {
      ...shorthands.borderColor(customTokens.colorDividerBackground1),
    },
    "::after": {
      ...shorthands.borderColor(customTokens.colorDividerBackground1),
    },
    }
});

export default function LoginPart({onLogin, onLoginWithAzure, setErrorMessage}) {
    const baseClassName = useBaseClassName();
    const classes = useStyles();

    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;

    /*
    const authorize = async () => {
        console.log('Authorization click 01');
        console.log('teamsUserCredential', teamsUserCredential);
        try {
            const result = await teamsUserCredential.login(["User.Read"]);   
            console.log('Login successful:', result);
        } catch (error) {
            console.error('Login failed:', error);
        }
    }
        */

  const authorizeConsent = async (callback, silent) => {
    //const tok = await teamsUserCredential.getToken(["https://graph.microsoft.com/User.Read"]);
    //console.log('Token:', tok);
    
    try {
        await Api.getKey();
        console.log("Consent: Key obtained, no consent required");
        callback();
    } catch (error) {
        console.log("Consent: Consent required, triggering login...", error);
        try {
            const result = await teamsUserCredential.login(["User.Read"]);
            console.log('Consent: Login successful:', result);
            callback();
        } catch (error) {
            if(silent) {
                //in silet mode we allow to continue - still we like to be able to login via name and password
                callback();
            } else {
                try {
                    await Api.getKey();
                } catch (error) {
                    setErrorMessage('In order to be able to use Azure login, please provide consent');
                }
            }
            console.log('Consent: Login failed:', error);
        }
    }
  }

    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        authorizeConsent(function(){
            onLogin(formData.get('username'), formData.get('password'));
        }, true)      
    };

    const preflightOnLoginWithAzure = () => {
        authorizeConsent(function(){
            onLoginWithAzure();
        }, false)        
    }

    return (
        <form onSubmit={onSubmit} className={baseClassName}>
            <Text className="block" weight="bold" size={600}>Login with your account</Text>
            <Field className="block">
                <Label htmlFor="username" size="small" style={{marginBottom: '5px'}}>
                Username
                </Label>
                <Input type="text" id="username" required name="username" autoComplete="email" style={{zIndex: 0}}/>
            </Field>
            <Field className="block">
                <Label htmlFor="password" size="small" style={{marginBottom: '5px'}}>
                Password
                </Label>
                <Input type="password" id="password" required name="password" />
            </Field>
            <Button className={mergeClasses('block', classes.actionBackground)} appearance="primary" type="submit">Login</Button>
            <Divider className={mergeClasses('block', classes.divider)}>Or</Divider>
            <Button className={mergeClasses('block')} onClick={preflightOnLoginWithAzure}>Login with Azure</Button>
            <Text className="block" weight="normal" size={200}>
            New here? If you don’t have an account yet. To get started, please visit https://www.otys.com/en/demo to create your account.
            If you’re facing any issues or have questions, feel free to contact us for assistance https://www.otys.com/en/support.
            </Text>
        </form>
    )
}