// https://fluentsite.z22.web.core.windows.net/quick-start
// https://developer.microsoft.com/en-us/fluentui#/controls/web/pivot
import {
  FluentProvider,
  Spinner,
} from "@fluentui/react-components";
import {
  createHashRouter,
  RouterProvider, 
  redirect} from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import ConfigPage from "./pages/ConfigPage";
import MeetingDetailsPage from "./pages/MeetingDetailsPage";
import { useEffect, useState } from "react";
import { customDarkTheme, customHighContrastTheme, customLightTheme } from "./utils/Theming";
import Ows from "./utils/OwsHandler";
import BadConfigPage from "./pages/BadConfigPage";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import config from "./config/config";
import { TeamsFxContext } from "./Context";

function themeStringToTheme(themeString) {
  switch (themeString) {
    case "dark":
      return customDarkTheme;
    case "contrast":
      return customHighContrastTheme;
    default:
      return customLightTheme;
  }
}

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  console.log('config', config);

  console.log('process.env', process.env.REACT_APP_START_LOGIN_PAGE_URL);

  const { loading, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });

  //console.log('teamsUserCredential', teamsUserCredential);

  const [initialized, setInitialized] = useState(false);
  const [theme, setTheme] = useState('default');
  
  useEffect(() => {
    (async () => {
      try {
        await microsoftTeams.app.initialize();
        const ctx = await microsoftTeams.app.getContext();
        microsoftTeams.app.notifyAppLoaded();
        microsoftTeams.app.notifySuccess();
        setInitialized(true);
        setTheme(ctx.app.theme);
        microsoftTeams.app.registerOnThemeChangeHandler((theme) => {
          setTheme(theme);
        });
        
      } catch (e) {
        microsoftTeams.app.notifyFailure(e);
        console.error(e);
      }
    })();
  }, [])

  if (!initialized) {
    return <Spinner />;
  }
  const meetingLoader = async () => {
    try {
      const ctx = await microsoftTeams.app.getContext();
      const {response: meeting, error} = await Ows.handleRequest(            
          'Otys.Services.CalendarRemoteService.getAppointmentContextInfoByMeetingId',
          [ctx.chat.id]
      );
      if (error) console.log(error);
      return meeting;
  } catch (e) {
      console.error(e);
      return redirect('/bad-config');
  } 
  };

  const router = createHashRouter([
    {
      path: "configure",
      element: <ConfigPage />
    },
    {
      path: "meeting-detail",
      loader: meetingLoader,
      element: <MeetingDetailsPage />
    },
    {
      path: "*",
      loader: () => redirect("meeting-detail")
    },
    {
      path: 'bad-config',
      element: <BadConfigPage />
    }
  ])

  return (
    <TeamsFxContext.Provider value={{ teamsUserCredential }}>
      <FluentProvider
        theme={themeStringToTheme(theme)}
        style={{ backgroundColor: 'transparent' }}
      >
        <RouterProvider router={router} />
      </FluentProvider>
      </TeamsFxContext.Provider>
  );
}
